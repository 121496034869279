<template>
  <div class="container person-warp flex-column">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img
        class="img-title"
        :src="require('@/assets/image/personInfo.png')"
        alt=""
      />
      <div class="head-bottom-warp">
        <div class="head-item align-center">
          <div class="bg-blur"></div>
          <van-dropdown-menu active-color="#3a86ff">
            <van-dropdown-item
              v-model="forms.levelOne"
              :options="oneList"
              @change="handleChangeOne"
            />
          </van-dropdown-menu>
          <img
            class="img-jiantou"
            :src="require('@/assets/image/jiantou.png')"
            alt=""
          />
        </div>
        <div class="head-item align-center">
          <div class="bg-blur"></div>
          <van-dropdown-menu active-color="#3a86ff">
            <van-dropdown-item
              v-model="forms.levelTwo"
              :options="twoList"
              @change="handleChangeTwo"
              :title="titleTwo"
            />
          </van-dropdown-menu>
          <img
            class="img-jiantou"
            :src="require('@/assets/image/jiantou.png')"
            alt=""
          />
          <van-icon name="close" v-if="forms.levelTwo" @click="handleClose" />
        </div>
        <div class="head-item align-center">
          <div class="bg-blur"></div>
          <van-dropdown-menu active-color="#3a86ff">
            <van-dropdown-item
              v-model="forms.levelThree"
              :options="threeList"
              @change="handleChangeThree"
              :title="titleThree"
            />
          </van-dropdown-menu>
          <img
            class="img-jiantou"
            :src="require('@/assets/image/jiantou.png')"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="search-content">
      <van-search
        v-model="forms.name"
        placeholder="搜索您要查找的内容(按姓名搜索)"
        background="rgb(248, 248, 248)"
        @search="onSearch"
        @clear="onClear"
      >
      </van-search>
    </div>
    <div class="flexone">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
        v-if="dataList.length && dataList.length > 0"
        offset="50"
      >
        <div
          class="commonInfo-content"
          v-for="(item, index) in dataList"
          :key="index"
          @click="routerTo(item)"
        >
          <div class="commonInfo-left">
            <div>
              <img
                :src="require('@/assets/image/path1.png')"
                alt=""
                class="path-img"
              />
            </div>
            <div class="commonInfoInner-right">
              <p class="commonInfo-name">{{ item.name }}</p>
              <p class="commonInfo-date">
                {{ item.birthday || "无" }}
              </p>
            </div>
          </div>
          <div class="persionInfo-right">
            <img
              :src="baseUrl + item.photo"
              alt=""
              class="person-img"
              v-if="item.photo"
            />
          </div>
        </div>
      </van-list>
      <div class="nothing" v-if="!dataList.length">
        <van-empty description="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin/mixin";
export default {
  mixins: [mixin],
  data() {
    return {
      interfaceUrl: "/api/people/get",
    };
  },
  mounted() {},
  methods: {
    routerTo(row) {
      this.$router.push({
        path: "personInfoDetail",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="less">
.person-warp {
  background: rgb(248, 248, 248);
  letter-spacing: 0px;
  position: relative;
  .img-jiantou {
    margin-left: 0 !important;
  }
  .person-img {
    width: 42px;
    height: 48px;
    object-fit: cover;
  }
}
</style>
